// 编辑器要安装的插件集合
// export default [];

// 调用免费的全部插件 
export default [
    'lists', 'advlist','autolink','link','image','imagetools',
    'autosave','wordcount','fullscreen','table','anchor',
    ...['preview','hr','code']
    // 'bbcode','charmap','code','codesample','directionality',
    // 'emoticons','fullpage','help','hr','importcss','insertdatetime',
    // 'legacyoutput','media','nonbreaking','noneditable','pagebreak',
    // 'paste','preview','print','quickbars','save','searchreplace',
    // 'spellchecker','template','textcolor','textpattern',
    // 'toc','visualblocks','visualchars',
];

/**
 * 特别备注使用需要注意的插件：
 * 1. autoresize会导致视图高度固定
 */
