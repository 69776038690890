// export default [
//     'undo redo',
//     'formatselect',
//     'bold italic',
//     'alignleft',
//     'aligncenter',
//     'alignright',
//     'alignjustify',
//     'bullist',
//     'numlist',
//     'outdent',
//     'indent',
//     'lists',
//     'image',
//     'media',
//     'table',
//     'removeformat'
// ]

// let jic = 'importword | undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat';
let toolbars = [
    // 富文本导入功能
    'importword',
    // 前进、后退功能
    'undo redo',
    // 文字格式选择功能，常见的标题选择
    'formatselect',
    // 粗体、斜体
    'bold italic',
    // 左边对齐、居中对齐、右边对齐、两端对齐
    'alignleft aligncenter alignright alignjustify',
    // 减少缩进
    'bullist numlist outdent indent',
    // 增加缩进
    'lists image media table',
    // 清楚格式
    'removeformat',
    // 文字颜色填充
    'forecolor backcolor',
    // 插入表格
    'quickbars',
    /**分割线 2021/11/22添加*/
    // 高级列表
    'bullist numlist',
    // 锚点插件
    'anchor',
    // 表情插件
    // 'emoticons',
    // 超链接
    'link',
    // 水平分割线
    'hr',
    // 预览
    'preview',
    // 字体选择、字体大小选择
    'fontselect | fontsizeselect',
    'code'
];
export default toolbars.join(" | ");