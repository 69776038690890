<template>
  <div
    :style="{
      marginTop: top + 'px',
      marginBottom: bottom + 'px',
      height: '500px',
    }"
  >
    <Editor
      v-model="myValue"
      api-key="6twaq5ln5bgt2vseemfex7dxpi32k1lv6sva55n7z0zrmp3o"
      :init="init"
      v-if="show"
    ></Editor>
  </div>
</template>

<script>
// 使用vue版的tinymce，插件文件引用变成请求官方插件库，这样国内一些自制的插件就无法使用
// 出于以后考虑，使用原版的tinymce可在以后扩展出使用国内第三方插件这个效果
import Editor from "@tinymce/tinymce-vue";

// 备注注释：在一个vue模块化项目中使用tinymce富文本框架，框架的设计对于文件引用是根据域名路径请求，而不是从依赖包中模块化引入
// 所以使用的时候报错'<'解析错误，因为请求目录没有这个文件
import tinymce from "tinymce/tinymce";
import props from "./tinymce-props.js";
import plugins from "./tinymce-plugins.js";
import toolbar from "./tinymce-toolbar.js";
// import './importword/plugin';
// import con from './concession.vue';
// import 'tinymce/icons/default/icons';
import api from "@/api/file/file.js";
import Vue from "vue";
export default {
  mixins: [props],
  components: {
    Editor,
    // con
  },
  mounted() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 100);
  },
  data() {
    return {
      show: false,
      inits: {},
      a: this,
      myValue: this.value,
      // tinymce-vue框架init对象，没有做响应式处理，这里需要开发者自己动脑筋
      init: {
        // auto_reset_designmode: true,
        // 指定下载一个语言包
        language_url: "/tinymce/zh_CN.js",
        // 指定默认语言
        language: "zh_CN",
        // 指定下载一个ui主题
        skin_url: "/tinymce/skins/ui/oxide",
        // 指定一个皮肤
        skin: "oxide",
        // 指定下载一个内容的默认样式
        content_css: "/tinymce/skins/content/default/content.css",
        // 指定高度
        height: this.height,
        // 打开状态栏，框架默认为true, props中是false
        menubar: this.menubar,
        // 设置占位符的文字
        placeholder: this.placeholder,
        // 是否显示下角技术支持
        branding: false,
        // 配置插件数组
        plugins: plugins,
        // 配置工具栏，他会覆盖默认工具栏数组
        toolbar: toolbar,
        // 指定从官方商店以外的地址注册插件
        external_plugins: {
          importword: "/importword/plugin.js",
        },
        file_picker_types: "image",
        images_upload_credentials: true,
        image_advtab: true,
        /**
         * @param bIobInfo 文件对象
         * @param success 文件保存远程服务器成功时候调用的回调函数
         * @param failure 文件保存远程服务器失败或者不符合规范时候调用
         */
        images_upload_handler: (bIobInfo, success, failure) => {
          let file = bIobInfo.blob();
          // console.log(
          //   "bIobInfo.base64()",bIobInfo.base64(),'\n',
          //   "bIobInfo",bIobInfo,'\n',
          //   'new window.File([file], bIobInfo.name(), {type: file.type})',new window.File([file], bIobInfo.name(), {type: file.type}),'\n',
          //   'file:',file,'\n'
          // )
          if (file.size > 2 * 1024 * 1024) {
            return failure("上传文件超出大小限制，请上传2mb以下的图片");
          }
          let formdata = new FormData();
          let file2 = new window.File([file], bIobInfo.filename(), {
            type: file.type,
          });
          formdata.append("file", file2);
          formdata.append("fileType", this.fileType);
          this.axios
            .post(api.upload_file, formdata, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              if (res.code === this.staticVal.Code.Success) {
                let name = bIobInfo.filename();
                if (!RegExp(/blob/).test(name)) {
                  this.snackbar.success("上传成功");
                }
                success(res.data.ossFileUrl);
              } else {
                failure(res.msg);
              }
            });
        },
      },
    };
  },
  methods: {
    vgg() {
      console.log(this.myValue);
    },
  },
  watch: {
    value(val) {
      this.myValue = val;
    },
    myValue: {
      handler(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>

<style>
/* //没有申请key的话会出现一个弹窗要去申请key,在这里把那个弹窗隐藏，当然也可以自己申请key再使用 */
/* .tox-notifications-container {
  display: none;
} */
/* //在页面正常使用时不用加这个样式，在弹窗使用时，要加这个样式，因为使用弹窗，z-index层数比较低，工具栏的一些工具不能使用，要将z-index层数提高。 */
/* .tox-tinymce-aux {
  z-index: 5000 !important;
} */
</style>