export default {
    props: {
        // 赋值给富文本的初始值
        value: {
            type: String,
            default: ''
        },
        // 是否开启菜单栏
        menubar: {
            type: Boolean,
            default: false
        },
        // 设置占位符文字
        placeholder: {
            type: String,
            default: '在这里输入文字'
        },
        // 指定高度
        height: {
            type: Number,
            default: 480
        },
        // 顶部间距
        top: {
            type: Number,
            default: 0
        },
        // 底部间距
        bottom: {
            type: Number,
            default: 24
        },
        // 文件上传目录归类的类型为
        fileType: {
            type: String,
            default: "roomType"
        },
        // 设置是否只读
        readonly: {
            type: Boolean,
            default: false
        }
    }
}